<template>
  <div class="form-check form-switch">
    <input
      :id="id"
      class="form-check-input"
      :class="$attrs.class"
      type="checkbox"
      :name="name"
      v-model="valor"
      @change="emitValue()"
    />
    <label class="form-check-label" :class="labelClass" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "SoftSwitch",
  data() {
    return {
      valor: this.value,
      id: Math.floor(Math.random() * 900) + 100
    }
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      default: false
    },
    checked: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    emitValue() {
      this.$emit('valor',this.valor)
    }
  }
  
};
</script>
