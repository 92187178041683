
<template>
  <div class="py-4 container-fluid mt-3">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Empresas:</h5>
            <clientsTable :showFilters="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import VsudPagination from "@/components/VsudPagination.vue"
import clientsTable from "@/views/components/Clients/table.vue"

export default {
  name: "clients",
  data() {
    return {

    };
  },
  components: {
    clientsTable,
    // VsudPaginationItem
  },
};
</script>
