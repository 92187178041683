<template>
  <div  class="contieneError">
    <div v-for="err in this.$store.state.errores" :key="err"  class="card alert-warning">
      <div class="card-body">
        <b>{{err.title}}</b> {{err.desc}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error"

};
</script>
