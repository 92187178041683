
<template>
  <div class="py-4 container-fluid mt-3">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h5 class="font-weight-bolder">Documentación:</h5> -->
            <tableDoc :showFilters="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tableDoc from "./components/Documentacion/table";

export default {
  name: "Documentacion",
  components: {
    tableDoc
  },
};
</script>
