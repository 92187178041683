<template>

  <MeetsTable/>
</template>

<script>

import MeetsTable from "@/views/components/Meets/table.vue";

export default {
  name: "authors-table",
  data() {
    return {

    };
  },
  components: {
    MeetsTable
    // VsudPagination,
    // VsudPaginationItem
  },
};
</script>
