<template>
     <div class="card mt-4">
        <div class="card-body">
            <div class="">
                <h4>{{meet.title}}</h4>
                <p>
                    <strong>Descripción: </strong> {{meet.description}} <br>
                    <strong>Lugar: </strong> {{meet.ubication}} <br>
                    <strong>Fecha: </strong> {{meet.meet_on}} a las {{meet.at}} <br>
                </p>
            <div class="opcionesMeet">
                <router-link
                :to="{
                    name: 'Reunión',
                    params: {
                    id: meet.id
                    }
                }">
                <button type="button" class="btn  btn-link blue" name="button">Abrir Reunión</button>
                </router-link>
            </div>
            </div>
                

        </div>
    </div>
</template>

<script>
    export default {
        name:"miniMeet",
        data() {
            return {

            }
        },
        props: {
            meet: {
                required: true
            }
        }
    }

</script>