<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName"  />
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div

          class="pe-md-3 d-flex align-items-center"
          :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          <!-- Icono empresas -->
          <svg   v-if="this.companiesIn.length>1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="margin:8px" fill="currentColor" class="bi bi-buildings-fill" viewBox="0 0 16 16">
            <path d="M15 .5a.5.5 0 0 0-.724-.447l-8 4A.5.5 0 0 0 6 4.5v3.14L.342 9.526A.5.5 0 0 0 0 10v5.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V14h1v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V.5ZM2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-1 2v1H2v-1h1Zm1 0h1v1H4v-1Zm9-10v1h-1V3h1ZM8 5h1v1H8V5Zm1 2v1H8V7h1ZM8 9h1v1H8V9Zm2 0h1v1h-1V9Zm-1 2v1H8v-1h1Zm1 0h1v1h-1v-1Zm3-2v1h-1V9h1Zm-1 2h1v1h-1v-1Zm-2-4h1v1h-1V7Zm3 0v1h-1V7h1Zm-2-2v1h-1V5h1Zm1 0h1v1h-1V5Z"/>
          </svg>
          <div>
            <select   v-if="this.companiesIn.length>1" @change="reloadParent" v-model="this.$store.state.currentCompany_id" class="form-select" style="width:auto;" aria-label="Default select example">
              <option  :value="null"> Todas </option>
              <option v-for="company in this.companiesIn"  :key="company.id" :value="company.id">{{company.name}}</option>
          </select>
          </div>
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center">
            <router-link
              :to="{
                path: '/user/'+this.$store.state.auth.id
              }"
              class="px-0 nav-link font-weight-bold"
            >
              <i
                class="fa fa-user me-sm-1"
              ></i>

              <span class="d-sm-inline d-none">
                {{this.$store.state.auth.name}}
              </span>
            </router-link>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-body"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
         
          <li
            class=" px-3 nav-item dropdown d-flex align-items-center"
            :class="'pe-2'"
          >
            
            <router-link
              :to="{
                path: '/notificaciones'
              }">
                <div class="text-center">
                    <i class="cursor-pointer fa fa-bell"></i>
                </div>
                <div v-if="this.$store.state.pendingNotis(this.$store.state.general.notifications).length !== 0" class="bouble boubleBell">
                  {{ this.$store.state.pendingNotis(this.$store.state.general.notifications).length  }}
                </div>
              </router-link>
            <!-- <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4 menuNNotis"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
            <span  class="mb-1">
              <b>Últimas Notificaciones</b>
             </span>

              <li  v-for="notification in this.$store.state.auth.notifications_resume" :key="notification.id"  class="mb-2 mt-2">
                <router-link class="dropdown-item border-radius-md" :to="{
                  path: this.loadPath(notification)
                }"
               >
                  <div class=" contineNotisListBar">
                    <div class="imagenN">
                      <i v-if="notification.read" class="fa fa-circle me-1 m-1 read"></i>
                      <i v-else class="fa fa-circle me-1 m-1 pending"></i>

                      <img
                        v-if="notification.from !== null && notification.from.profile_image !== null"
                        :src="notification.from.profile_image.url+'/100.'+notification.from.profile_image.format"
                        class="avatar avatar-sm me-3"
                        alt="user image"
                      />


                      <img
                        v-else
                        :src="this.$store.state.baseUrl+'/images/profiles/default.png'"
                         class="avatar avatar-sm me-3"
                        alt="user image"
                        />
                    </div>
                    <div class="contentN">
                      <h6 class="mb-1 text-sm font-weight-normal spanNoti">
                        <span class="font-weight-bold">{{notification.title}}</span>
                        <br>

                      </h6>

                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        {{this.$store.state.dateFormat(notification.created_at)}}
                      </p>

                    </div>

                  </div>

                </router-link>
              </li>
              <router-link
              :to="{
                path: '/notificaciones'
              }">
                <div class="text-center">
                  <button class="btn btn-link text-center">Ver Todas</button>
                </div>
              </router-link>
            </ul> -->
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      companies: null
    };
  },
  props: ["minNav",'companiesIn'],
  created() {
    this.minNav;
    this.companies = this.companiesIn
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    loadPath(noti) {
      if(noti.url == null) {
        return "/notificaciones"
      } else {
        return "/"+noti.url;
      }
    },
    // pendingNotis() {
    //   var n = this.$store.state.general.notifications;
    //   return n.filter(noti => {
    //     return noti.read == false
    //   })
    // },
    // asignamos actual y recargamos pçagina
    reloadParent() {
      this.$store.state.errores = []

      for (var i = 0; i < this.companiesIn.length; i++) {
        if(this.companiesIn[i].id == this.$store.state.currentCompany_id){
          this.$store.state.currentCompany = this.companiesIn[i]
          i = this.companiesIn.length
        }
      }
      if(this.$store.state.currentCompany_id == null)
        this.$store.state.currentCompany = null;
      // si estamos en la pantalla de chats en alguno en concreto deberiamos redirigir a a la de listado 
      if(this.$route.name == 'Chat')
        this.$router.push({path: '/chats' })
      this.$emit('reload');
    }
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  updated() {
    const navbar = document.getElementById("navbarBlur");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10 && this.$store.state.isNavFixed) {
        navbar.classList.add("blur");
        navbar.classList.add("position-sticky");
        navbar.classList.add("shadow-blur");
      } else {
        navbar.classList.remove("blur");
        navbar.classList.remove("position-sticky");
        navbar.classList.remove("shadow-blur");
      }
    });
  },
};
</script>
