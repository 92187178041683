<template>
  <div class="py-4 container-fluid mt-3">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Formularios Pendientes:</h5>
            <router-link
            v-if="this.$store.state.auth.rol == 'asesor'"
            :to="{
              name: 'Requerir Formulario',

            }">
            <button type="button" class="btn btn-secondary" name="button">Requerir Formulario</button>
          </router-link>
            <formsTable :showFilters="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import VsudPagination from "@/components/VsudPagination.vue"
import formsTable from "@/views/components/Forms/table.vue"

export default {
  name: "tickets",
  data() {
    return {

    };
  },
  components: {
    formsTable,
    // VsudPaginationItem
  },
};
</script>
