<template>
  <div class="row">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li  class='breadcrumb-item'>
          <router-link
            :to="{
              name: 'Documentacion'
              }">
            Documentación
          </router-link>
        </li>
        <li v-for="miga in this.migas.slice().reverse()"  :key="miga.id"  class='breadcrumb-item'>
          <router-link
            :to="{
              name: 'Documentacion',
              params: {
                folder_id: miga.id
              }

            }">
            {{miga.name}}
          </router-link>
        </li>

      </ol>
    </nav>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        migas: []
      }
    },
    props: {
      info: {
        default : []
      }
    },
    created() {
      this.teste(this.info)
    },
    methods: {
      teste(folder) {
        if(folder == null)
          return;
        this.migas.push(folder)
        if(folder.folders_road !== null)
          this.teste(folder.folders_road)
      }
    }

  }
</script>
