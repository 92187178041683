<template>
  <div v-if="this.$store.state.auth.rol == 'client'" class="card">
    <div class="card-body p-3">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-column h-100">
            <!-- Si solo hay una -->
            <div v-if="this.$store.state.currentCompany !== null" class="mb-5">
              <h5 class="font-weight-bolder">Mi empresa</h5>
              <p>
                <strong>Nombre: </strong> {{this.$store.state.currentCompany.name}} <br>
              </p>
            </div>
            <!-- Más de una empresa -->
            <div v-else class="">
              <h5 class="font-weight-bolder">Mis empresas</h5>
              <p v-for="company in this.$store.state.general.companies"  :key="company.id">
                <strong>Nombre: </strong>
                <router-link
                  :to="{
                    path: '/company/'+company.id
                  }"
                  >
                  {{company.name}}
                </router-link>
                <br>
              </p>
            </div>

            <router-link
              class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
              :to="{
                name: 'User',
                params: {
                  id: this.$store.state.auth.id
                }
              }"

            >
              Ver todos los datos
              <i
                class="fas fa-arrow-right text-sm ms-1"
                aria-hidden="true"
              ></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "infoCompany",
  props: ['companies','current'],
  data() {
    return {

    }
  }

}

</script>
