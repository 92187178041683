<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-md-12">
        <Team/>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="flex">
              <h5 class="font-weight-bolder">Próximas reuniones:</h5>
              <router-link class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                :to="{
                  name:'Reuniones'
                  }"
              >
                Ver todas
                <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
              </router-link>
            </div>
            <nextMeets></nextMeets>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Asuntos/Incidencias:</h5>
            <tickets></tickets>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nextMeets from "@/views/components/Comunicacion/nextMeets.vue";
import tickets from "@/views/components/Tickets/table.vue";
import Team from "@/views/components/Comunicacion/teamAsigned.vue";
import img3 from "@/assets/img/team-4.jpg";


export default {
  data() {
    return {
      img3
    };
  },
  name: "equipo",
  components: {
    nextMeets,
    tickets,
    Team
  }

};
</script>
