<template>

  <div class="card">
  <div class="card-header">
    <div class="col-md-12">
      <ul class="nav nav-tabs">
        <li v-for="(apartado,index) in this.info.columns"  :key="apartado" class="nav-item cursor-pointer">
          <a class="nav-link " v-bind:class="{ 'active': this.selection==index }" @click="changeApartado(index)" >
            <h6>{{index}}</h6>
          </a>
          
        </li>
        <!-- <li class="nav-item cursor-pointer">
          <a class="nav-link " v-bind:class="{ 'active': this.selection==index }" @click="changeApartado('all')" >
            <h5>Todos</h5>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
    <div class="card-body row">
      <div class="col-md-6" v-for="(campo,index) in this.info.columns[selection]"  :key="index">
        <div v-if="!campo.label && !campo.type" class="">
          <p><b>{{campo}}:</b> {{info[index]}}</p>
        </div>
        <div v-else-if="campo.label" >
          <div v-if="campo.type == 'bool'" class=" d-flex">
            <b>{{campo.label}}:</b>
            <!-- if true -->
            <div v-if="info[index]">
              <div class="form-check">
                <input class="form-check-input" checked disabled type="checkbox" value="" :id="info[index]">
              </div>
            </div>
            <!-- Not true -->
            <div v-else>
              <input class="form-check-input"  disabled type="checkbox" value="" :id="info[index]">
            </div>
          </div>
          <p v-else><b>{{campo.label}}:</b> {{info[index]}}</p>
        </div>
        <div v-else class="">
          <p><b>{{index}}:</b> {{info[index]}}</p>
        </div>


      </div>
    </div>
  </div>
</template>

<script>

  export default {
    data(){
      return {
        selection: null
      }
    },
    name: "Info",
    props: ['info'],
    mounted() {
      // para que esté el rimerp seleccionado por defecto
      this.selection = Object.keys(this.info.columns)[0]
    },
    methods: {
      changeApartado(apartado) {
        this.selection = apartado

      }
    }


  };
  </script>
