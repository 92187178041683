<template>
  <div class="py-4 container-fluid mt-3">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Notificaciones</h5>
            <span> {{ this.pendingNotis().length }} pendientes de {{ this.$store.state.general.notifications.length }} en total</span>
            <notiTable :showFilters="true" />
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import VsudPagination from "@/components/VsudPagination.vue"
import notiTable from "@/views/components/Notification/table.vue"

export default {
  name: "tickets",
  data() {
    return {

    };
  },
  components: {
    notiTable,
    // VsudPaginationItem
  },
  methods: {
    pendingNotis() {
      var n = this.$store.state.general.notifications;
      return n.filter(noti => {
        return noti.read == false
      })
    },
  }
};
</script>
