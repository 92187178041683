<template>
  <div v-on:mouseover="resetear()"  class="alert alert-secondary alert-dismissible notiPush" role="alert">
    <strong>{{notification.title}} </strong> - {{notification.body}}
    <!-- <a href="#" @click="borrar()" class="close" data-dismiss="alert" aria-label="close">&times;</a> -->
    <div class="contieneOpcionesNotiPush">
      <button @click="goUrl()" type="button" class="btn btn-secondary m-1" name="button">Abrir</button>
      <button @click="borrar()" type="button" class="btn btn-secondary m-1" name="button">Borrar</button>

    </div>
  </div>
</template>

<script>
export default {
  name: "pushNoti",
  data() {
    return {
      timer: setTimeout(()=>{ this.borrar(); }, 2000)

    }
  },
  props: ['notification','index'],
  mounted() {
    this.timer;
  },
  methods: {
    borrar() {
      // this.$store.state.auth.notifications_resume.unshift(noti)
      this.$store.state.notificationsPush.splice(this.index, 1); // 2nd parameter means remove one item only

    },

    resetear() {
      clearTimeout(this.timer)
      this.timer = setTimeout(()=>{ this.borrar(); }, 4000)
    },
    goUrl() {
      console.log(this.notification)
      this.$router.push({path: '/'+this.notification.url })
      this.borrar();
      
      // window.location.href = this.notification.data.url
    }
  }

};
</script>
