<template>
<div class="col-lg-5 mb-lg-0 mb-4">
  <div class="card">
    <div class="card-body p-3">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-column h-100">
            <h5 class="font-weight-bolder">Información/Noticias</h5>
            <h5  v-if="news.length === 0">Parece que no hay notícias que mostrar</h5>
            <div v-for="neww in news.slice(0,this.max)"  :key="neww.id" class="row p-1">

              <div class="col-4 ms-auto text-center mt-3 mt-lg-0 contImNew">
                <div v-if="neww.image == null" class="contimgNew">
                  <router-link
                    :to="{
                      path: '/news/'+neww.id
                    }">
                    <img
                      :src="url"
                      class="imgNew"
                      alt="waves"
                      width="80"
                    />
                  </router-link>

                </div>
                <div v-else class="contimgNew">
                  <router-link
                    :to="{
                      path: '/news/'+neww.id
                    }">
                    <img
                      :src="neww.image.url+'/400.'+neww.image.format"
                      class="imgNew"
                      alt="waves"
                      width="80"
                    />
                  </router-link>
                </div>
              </div>
              <div class="col-8">
                <div class="d-flex flex-column h-100">
                  <router-link
                    :to="{
                      path: '/news/'+neww.id
                    }">
                  <h6 class="font-weight-bolder">{{neww.title}}</h6>
                </router-link>
                  <p class=" small">
                    {{neww.subtitle}}
                  </p>
                <small class=" small">
                  {{neww.publish_on}}
                </small>


                </div>
              </div>


            </div>


            <a
              class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
              href="javascript:;"
              @click="mostrarNoticias()"
              v-if="!showAllNews"
            >
              Notícias
              <i
                class="fas fa-arrow-right text-sm ms-1"
                aria-hidden="true"
              ></i>
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>


export default {
  name: "News",
  props: {
    news: {
      type: Array,

    }
  },
  components: {

  },
  data() {
    return {
      loading: false,
      url: this.$store.state.baseUrl+"/images/profiles/default.png",
      max:3,
      showAllNews: false

    }
  },
  methods: {
    mostrarNoticias() {
      this.max=1000
      this.showAllNews = true
    }
  }
}

</script>
